export default class Standard {
  constructor(id) {
    this.container = document.querySelector(id);
    this.base = 'http://localhost:85/erste-hilfe/public/';
  }
  set(selector, all = false) {
    if (all === true) {
      return this.container.querySelectorAll(selector);
    } else {
      return this.container.querySelector(selector);
    }
  }
  exist() {
    if (this.container !== null) {
      return true;
    } else {
      return false;
    }
  }

  contains(data, value) {
    let bool = false;
    for (const [key, val] of Object.entries(data)) {
      if (val === value) {
        bool = true;
      }
    }
    return bool;
  }

  // ohen / mpüsst gehen
  setEndpoint(path, dev = false) {
    if (dev === false) {
      return document.querySelector('base').href + path;
    } else {
      return this.base + path;
    }
  }
  getParam(name) {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has(name)) {
      return urlParams.get(name);
    } else {
      return false;
    }
  }
  // hier ohne /
  redirect(path, newTab = false) {
    if (newTab === true) {
      window.open(path, '_blank');
    }
    window.location.href = path;
  }
  redirect_base(path, newTab = false) {
    const base = document.querySelector('base').href;
    if (newTab === true) {
      window.open(base + path, '_blank');
    } else {
      window.location.href = base + path;
    }
  }
}
