import jump from 'jump.js';

const footer_year = document.querySelector('#footer_year');
if (footer_year !== null) {
  var date = new Date();
  footer_year.innerText = date.getFullYear();
}

const home_to_kurse_btn = document.querySelector('#home_to_kurse_btn');
if (home_to_kurse_btn !== null) {
  home_to_kurse_btn.addEventListener('click', (e) => {
    e.preventDefault();
    jump('#erste-hilfe-kurse', {
      duration: (distance) => Math.abs(distance),
    });
  });
}
