import Standard from '../../js/Libraries/Standard';
import ZachiHttp from '../../js/Libraries/ZachiHttp';
import Handlers from '../../js/Libraries/Handlers';

export default class PrintAGB extends Standard {
  constructor() {
    super('#PrintAGB');
    if (!super.exist()) return;

    this.http = new ZachiHttp();
    this.hl = new Handlers();
    this.endpoint = super.setEndpoint('pages_api/download_url');
    this.btn = super.set('#generate_agb_pdf_btn');
    this.init();
  }

  init() {
    this.generate_pdf();
  }

  async generate_pdf() {
    if (this.btn === null) {
      return;
    } else {
      const data = {
        title: 'AGB-Erste-Hilfe',
        url: window.location.origin,
        view_name: 'law/agb_as_pdf',
      };
      console.log(data);

      this.btn.addEventListener('click', async () => {
        console.log(213123);
        var res = await this.http.download(this.endpoint, data);
      });
    }
  }
}
