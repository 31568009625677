import Standard from '../../js/Libraries/Standard';
import ZachiHttp from '../../js/Libraries/ZachiHttp';
import Handlers from '../../js/Libraries/Handlers';
import jump from 'jump.js';
import {
  readLinebreaks,
  checkValidEmail,
  checkValidPhone,
} from '../../js/Libraries/Form';

export default class Ersatzbescheinigung extends Standard {
  constructor(id) {
    super(id);
    if (!super.exist()) return;
    this.http = new ZachiHttp();
    this.hl = new Handlers();
    this.data = {
      name: false,
      email: false,
      phone: false,
      birthday: false,
    };
    this.sentBtn = super.set('#sentBtn');
    this.errorTop = super.set('#errorTop');
    this.errorEmail = super.set('#errorEmail');
    this.errorPhone = super.set('#errorPhone');
    this.errorLaw = super.set('#errorLaw');
    this.inputs = super.set('input[type="text"]', true);
    this.input_birthday = super.set('.birthday-input');
    this.email = super.set('#email');
    this.phone = super.set('#phone');
    this.law = super.set('#checkbox-law');
    this.endpoint = super.setEndpoint('pages_api/kontakt_birthday');
    this.init();
  }
  init() {
    this.events();
  }
  checkEmail() {
    // check valid email
    const email = this.email.value.trim();
    if (!checkValidEmail(email)) {
      this.email.classList.add('error');
      this.errorEmail.classList.add('error');
      this.data['email'] = false;
    } else {
      this.email.classList.remove('error');
      this.errorEmail.classList.remove('error');
      this.data['email'] = email;
    }
  }
  checkPhone() {
    const phone = this.phone.value.trim();
    if (!checkValidPhone(phone)) {
      this.phone.classList.add('error');
      this.errorPhone.classList.add('error');
      this.data['phone'] = false;
    } else {
      this.phone.classList.remove('error');
      this.errorPhone.classList.remove('error');
      this.data['phone'] = phone;
    }
  }
  checkBirthdayValue(str, max) {
    if (str.charAt(0) !== '0' || str == '00') {
      let num = parseInt(str);
      if (isNaN(num) || num <= 0 || num > max) num = 1;
      if (
        num > parseInt(max.toString().charAt(0)) &&
        num.toString().length == 1
      ) {
        str = '0' + num;
      } else {
        str = num.toString();
      }
    }
    return str;
  }
  listenBirthday() {
    // this.type = 'text';
    let input = this.input_birthday.value;
    let currentYear = new Date().getFullYear();
    if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
    let values = input.split('.').map(function (v) {
      return v.replace(/\D/g, '');
    });
    if (values[0]) values[0] = this.checkBirthdayValue(values[0], 31); // day check
    if (values[1]) values[1] = this.checkBirthdayValue(values[1], 12); // month check
    if (values[1]) values[1] = this.checkBirthdayValue(values[1], currentYear); // year check
    let output = values.map(function (v, i) {
      return v.length == 2 && i < 2 ? v + '.' : v;
    });
    this.input_birthday.value = output.join('').substr(0, 10);
  }

  checkBirthday() {
    if (this.input_birthday.value.length !== 10) {
      this.input_birthday.classList.add('error');
      this.data['birthday'] = false;
    } else {
      this.input_birthday.classList.remove('error');
      this.data['birthday'] = this.input_birthday.value;
    }
  }

  async sent() {
    this.inputs.forEach((each) => {
      if (each.value.trim() === '') {
        each.classList.add('error');
        this.data[each.name] = false;
      } else {
        each.classList.remove('error');
        this.data[each.name] = each.value.trim();
      }
    });

    this.checkEmail();
    this.checkPhone();
    this.checkBirthday();

    if (super.contains(this.data, false)) {
      jump('#errorTop', {
        duration: (distance) => Math.abs(distance) * 4,
      });
      this.errorTop.classList.add('error');
      return;
    }

    this.errorTop.classList.remove('error');

    if (!this.law.checked) {
      this.errorLaw.classList.add('error');
      return;
    }
    this.hl.toggle();
    console.log(this.data);
    const res = await this.http.post(this.endpoint, this.data);
    console.log(res);
    await this.hl.wait();
    if (res.status === 200) {
      this.clean_input();
      this.hl.message(
        'Deine Nachricht wurde erfolgreich versendet. Wir werden uns so schnell wie möglich mit dir in Kontakt setzen!'
      );
      // super.redirect(res.redirect);
    } else {
      if (res.hasOwnProperty('error_text')) {
        this.hl.message(res.error_text);
      } else {
        this.hl.message(
          'Es gibt zurzeit leider Probleme mit unserem Server. Versuche es später noch einmal.'
        );
      }
    }
    return this.hl.toggle();
  }
  clean_input() {
    this.inputs.forEach((each) => {
      each.value = '';
    });
    this.law.checked = false;
  }
  events() {
    this.sentBtn.addEventListener('click', () => this.sent());
    this.inputs.forEach((each) => {
      each.addEventListener('focus', () => each.classList.remove('error'));
    });
    this.input_birthday.addEventListener('input', () => {
      this.listenBirthday();
    });
  }
}
