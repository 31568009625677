import Standard from '../../js/Libraries/Standard';
import ZachiHttp from '../../js/Libraries/ZachiHttp';
import Handlers from '../../js/Libraries/Handlers';
import jump from 'jump.js';
import {
  readLinebreaks,
  checkValidEmail,
  checkValidPhone,
} from '../../js/Libraries/Form';

export default class Kontakt extends Standard {
  constructor(id) {
    super(id);
    if (!super.exist()) return;
    this.http = new ZachiHttp();
    this.hl = new Handlers();
    this.data = {
      name: false,
      email: false,
      phone: false,
      message: false,
    };
    this.sentBtn = super.set('#sentBtn');
    this.errorTop = super.set('#errorTop');
    this.errorEmail = super.set('#errorEmail');
    this.errorPhone = super.set('#errorPhone');
    this.errorLaw = super.set('#errorLaw');
    this.inputs = super.set('input[type="text"]', true);
    this.email = super.set('#email');
    this.phone = super.set('#phone');
    this.law = super.set('#checkbox-law');
    this.textarea = super.set('textarea');
    this.endpoint = super.setEndpoint('pages_api/kontakt');

    this.events();
  }
  checkEmail() {
    // check valid email
    const email = this.email.value.trim();
    if (!checkValidEmail(email)) {
      this.email.classList.add('error');
      this.errorEmail.classList.add('error');
      this.data['email'] = false;
    } else {
      this.email.classList.remove('error');
      this.errorEmail.classList.remove('error');
      this.data['email'] = email;
    }
  }

  checkPhone() {
    const phone = this.phone.value.trim();
    if (!checkValidPhone(phone)) {
      this.phone.classList.add('error');
      this.errorPhone.classList.add('error');
      this.data['phone'] = false;
    } else {
      this.phone.classList.remove('error');
      this.errorPhone.classList.remove('error');
      this.data['phone'] = phone;
    }
  }
  checkTextarea() {
    const msg = readLinebreaks(this.textarea.value);
    if (msg === '') {
      this.data['message'] = false;
      this.textarea.classList.add('error');
    } else {
      this.data['message'] = msg;
      this.textarea.classList.remove('error');
    }
  }
  async sent() {
    this.inputs.forEach((each) => {
      if (each.value.trim() === '') {
        each.classList.add('error');
        this.data[each.name] = false;
      } else {
        each.classList.remove('error');
        this.data[each.name] = each.value.trim();
      }
    });

    this.checkEmail();
    this.checkPhone();
    this.checkTextarea();

    if (super.contains(this.data, false)) {
      jump('#errorTop', {
        duration: (distance) => Math.abs(distance) * 4,
      });
      this.errorTop.classList.add('error');
      return;
    }

    this.errorTop.classList.remove('error');

    if (!this.law.checked) {
      this.errorLaw.classList.add('error');
      return;
    }
    this.hl.toggle();
    console.log(this.data);
    const res = await this.http.post(this.endpoint, this.data);
    console.log(res);
    await this.hl.wait();
    if (res.status === 200) {
      this.clean_input();
      this.hl.message(
        'Deine Nachricht wurde erfolgreich versendet. Wir werden uns so schnell wie möglich mit dir in Kontakt setzen!'
      );

      // super.redirect(res.redirect);
    } else {
      if (res.hasOwnProperty('error_text')) {
        this.hl.message(res.error_text);
      } else {
        this.hl.message(
          'Es gibt zurzeit leider Probleme mit unserem Server. Versuche es später noch einmal.'
        );
      }
    }
    return this.hl.toggle();
  }

  clean_input() {
    this.inputs.forEach((each) => {
      each.value = '';
    });
    this.textarea.value = '';
    this.law.checked = false;
  }
  events() {
    this.sentBtn.addEventListener('click', () => this.sent());
    this.inputs.forEach((each) => {
      each.addEventListener('focus', () => each.classList.remove('error'));
    });
    this.textarea.addEventListener('focus', () =>
      this.textarea.classList.remove('error')
    );
  }
}
