import Standard from '../../js/Libraries/Standard';
import ZachiHttp from '../../js/Libraries/ZachiHttp';
import Handlers from '../../js/Libraries/Handlers';
import jump from 'jump.js';

export default class FAQ_accordion extends Standard {
  constructor() {
    super('#faq');
    if (!super.exist()) return;
    this.questions = super.set('.accordion-title', true);
    this.items = super.set('.accordion-item', true);
    this.frage = super.getParam('frage');
    this.init();
  }
  init() {
    this.init_accordion();
  }

  init_accordion() {
    for (const item of this.items) {
      const answer = item.querySelector('.accordion-content');
      const remove = item.querySelector('.remove');
      const add = item.querySelector('.add');
      let open = false; //Variable to check if the answer is visible or not
      if (item.dataset.frage == this.frage) {
        add.style.display = 'none';
        remove.style.display = 'block';
        answer.style.maxHeight = '3000px';
        answer.style.overflow = 'visible';
        open = true;
      }

      function openAnswer() {
        if (open == true) {
          //If you click the question while the answer is visible it will stop being visible and open will change it's value to false
          add.style.display = 'block';
          remove.style.display = 'none';
          answer.style.overflow = 'hidden';
          answer.style.maxHeight = '0';
          open = false;
        } else {
          //If you click the question while the answer is not visible it will start being visible and open will change it's value to true
          add.style.display = 'none';
          remove.style.display = 'block';
          answer.style.maxHeight = '3000px';
          answer.style.overflow = 'visible';
          open = true;
        }
      }

      item
        .querySelector('.accordion-title')
        .addEventListener('click', openAnswer);
    }
  }
}
