/* 
<?php if(isset($data['BoxMessage'])){
  ?>
    <div id="BoxMessage">
      <div class="content">
        <div class="close"><i class="far fa-times-circle"></i></div>
        <p class="pt-4">
            <?php echo $data['BoxMessage'];?> 
        </p>
      </div>
  </div>
<?php } ?>
*/

//  Box from top
export default class BoxMessage {
  constructor() {
    this.box = document.querySelector('#BoxMessage');
    this.btn = this.box.querySelector('.close');
  }
  toggle() {
    this.box.classList = 'active';
    // this.box.querySelector('p').innerText = msg;
    function closeBox(self) {
      self.box.classList.remove('active');
    }
    function outsideClick(e, self) {
      if (e.target == self.box) {
        self.box.classList = '';
      }
    }
    this.btn.addEventListener('click', () => closeBox(this));
    document.addEventListener('click', (e) => outsideClick(e, this));
  }
}
